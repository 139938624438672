dmx.Component('bs5-collapse', {

  initialData: {
    collapsed: true
  },

  attributes: {
    show: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggle () {
      this._instance.toggle();
    },

    show () {
      this._instance.show();
    },

    hide () {
      this._instance.hide();
    }
  },

  events: {
    show: Event,
    shown: Event,
    hide: Event,
    hidden: Event
  },

  init (node) {
    node.classList.add('collapse');

    node.addEventListener('show.bs.collapse', this.dispatchEvent.bind(this, 'show'));
    node.addEventListener('shown.bs.collapse', this.dispatchEvent.bind(this, 'shown'));
    node.addEventListener('hide.bs.collapse', this.dispatchEvent.bind(this, 'hide'));
    node.addEventListener('hidden.bs.collapse', this.dispatchEvent.bind(this, 'hidden'));

    node.addEventListener('shown.bs.collapse', this._shownHandler.bind(this));
    node.addEventListener('hidden.bs.collapse', this._hiddenHandler.bind(this));

    const options = { toggle: false };

    if (node.hasAttribute('data-bs-parent')) {
      options.parent = node.getAttribute('data-bs-parent');
    }

    this._instance = new bootstrap.Collapse(node, options);
    this._instance[this.props.show ? 'show' : 'hide']();
  },

  destroy () {
    this._instance.dispose();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('show')) {
      this._instance[this.props.show ? 'show' : 'hide']();
      this.set('collapsed', !this.props.show);
    }
  },

  _shownHandler () {
    this.set('collapsed', false);
  },

  _hiddenHandler () {
    this.set('collapsed', true);
  },

});
